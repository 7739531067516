import React from "react";
import { graphql } from "gatsby";
import Page from "../components/Page";

const CookiesPage = ({ data }: { data: any }) => {
  const WP = data.wp.pages.edges[0].node;
  const WP_ACF = WP.PrivacyPolicyAcf;
  const WP_SEO = WP.seo;
  const WP_TITLE = WP.title;

  const imageDesktop =
    WP_ACF.heroBackgroundImageDesktop !== null
      ? WP_ACF.heroBackgroundImageDesktop.imageFile
      : "";

  const imageMobile =
    WP_ACF.heroBackgroundImageMobile !== null
      ? WP_ACF.heroBackgroundImageMobile.imageFile
      : WP_ACF.heroBackgroundImageDesktop !== null
      ? WP_ACF.heroBackgroundImageDesktop.imageFile
      : "";

  const imageAlt =
    WP_ACF.heroBackgroundImageDesktop !== null
      ? WP_ACF.heroBackgroundImageDesktop.altText
      : "";

  return (
    <Page
      desktopImage={imageDesktop}
      mobileImage={imageMobile}
      imageAlt={imageAlt}
      title={WP_TITLE}
      content={WP_ACF.content || ""}
      seoTitle={WP_SEO.title || WP_TITLE}
      seoDesc={WP_SEO.metaDesc}
      seoUrl={'/cookies/'}
      seoImageUrl={WP_SEO.opengraphImage.sourceUrl || ""}
    />
  );
};

export default CookiesPage;

export const pageQuery = graphql`
  query {
    wp {
      pages(where: { name: "privacy-policy" }) {
        edges {
          node {
            title
            seo {
              title
              canonical
              focuskw
              metaDesc
              metaKeywords
              metaRobotsNofollow
              metaRobotsNoindex
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              twitterDescription
              twitterTitle
              opengraphPublisher
              opengraphSiteName
              opengraphUrl
              opengraphType
              opengraphTitle
              breadcrumbs {
                text
                url
              }
              opengraphImage {
                altText
                caption
                commentCount
                commentStatus
                databaseId
                date
                dateGmt
                description
                desiredSlug
                guid
                id
                isRestricted
                link
                mediaItemId
                mediaItemUrl
                mediaType
                mimeType
                modified
                modifiedGmt
                sizes
                slug
                sourceUrl
                srcSet
                status
                title
                seo {
                  breadcrumbs {
                    text
                    url
                  }
                  canonical
                  focuskw
                  metaDesc
                  metaKeywords
                  metaRobotsNofollow
                  metaRobotsNoindex
                  opengraphAuthor
                  opengraphDescription
                  opengraphModifiedTime
                  opengraphPublishedTime
                  opengraphPublisher
                  opengraphSiteName
                  opengraphTitle
                  opengraphType
                  opengraphUrl
                  title
                  twitterDescription
                  twitterTitle
                }
                enclosure
              }
              twitterImage {
                altText
                caption
                commentCount
                commentStatus
                databaseId
                date
                dateGmt
                description
                desiredSlug
                enclosure
                guid
                id
                isRestricted
                link
                mediaItemId
                mediaItemUrl
                title
                status
                srcSet
                sourceUrl
                slug
                sizes
                modifiedGmt
                modified
                mimeType
                mediaType
              }
            }
            PrivacyPolicyAcf {
              fieldGroupName
              heroBackgroundImageDesktop {
                altText
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: NONE
                      width: 1400
                      quality: 70
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              heroBackgroundImageMobile {
                altText
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: NONE
                      width: 1400
                      quality: 70
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
              content
            }
          }
        }
      }
    }
    bannerDesktop: file(relativePath: { eq: "blogs-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          formats: [AUTO, WEBP]
        )
      }
    }
    bannerMobile: file(relativePath: { eq: "blogs-banner-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 800
          quality: 70
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;
